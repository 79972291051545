// stores/siteStore.ts
import { defineStore } from "pinia";

interface GlobalSeo {
  twitterAccount: string;
  titleSuffix: string;
  siteName: string;
  fallbackSeo: {
    twitterCard: string;
    title: string;
    noIndex: boolean;
    image: {
      url: string;
    };
    description: string;
  };
  facebookPageUrl: string;
}

interface SiteData {
  noIndex: boolean;
  globalSeo: GlobalSeo;
  favicon: {
    url: string;
  };
  faviconMetaTags: Array<{
    attributes: Record<string, string>;
  }>;
}

export const useSiteStore = defineStore("site", {
  state: () => ({
    siteData: null as SiteData | null,
    error: null as Error | null,
    loading: false,
  }),

  getters: {
    siteName: (state) => state.siteData?.globalSeo.siteName,
    titleSuffix: (state) => state.siteData?.globalSeo.titleSuffix,
    isLoading: (state) => state.loading,
  },

  actions: {
    async fetchSiteData() {
      // Don't fetch if we already have the data
      if (this.siteData) return this.siteData;

      // Set loading state
      this.loading = true;

      const SITE_QUERY = `
        query {
          site: _site {
            noIndex
            globalSeo {
              twitterAccount
              titleSuffix
              siteName
              fallbackSeo {
                twitterCard
                title
                noIndex
                image {
                  url
                }
                description
              }
              facebookPageUrl
            }
            favicon {
              url
            }
            faviconMetaTags {
              attributes
            }
          }
        }
      `;

      try {
        const { data, error } = await useGraphqlQuery({
          query: SITE_QUERY,
        });

        if (error.value) {
          throw error.value;
        }

        this.siteData = data.value?.site;
        return this.siteData;
      } catch (err) {
        console.error("Failed to fetch site data:", err);
        this.error = err as Error;
        throw err;
      } finally {
        this.loading = false;
      }
    },
  },
});
