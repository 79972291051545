<template>
  <div>
    <NuxtLayout />
    <UNotifications />
    <LoginModal />
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useAuth } from "@/composables/useAuth";
import { useUserStore } from "@/stores/userStore";
import { useSiteStore } from '@/stores/siteStore'


const route = useRoute();
const runtimeConfig = useRuntimeConfig();
const userStore = useUserStore();
const siteStore = useSiteStore()

const { checkAuth, loggedIn } = useAuth();


// Define `fullUrl` as a computed property
const fullUrl = computed(() => {
  const baseUrl = runtimeConfig.BASE_URL || "http://localhost:3000";
  return `${baseUrl}${route.fullPath}`;
});


onMounted(async () => {
  try {
    await userStore.fetchUserProfile();
    const { pending } = await siteStore.fetchSiteData()

  } catch (error) {
    console.error("Error fetching user profile:", error);
  }
});




</script>


<style>
body {
  @apply min-h-screen;
}

.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
