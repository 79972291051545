import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/opt/build/repo/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/opt/build/repo/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/build/repo/.nuxt/sentry-client-config.mjs";
import slideovers_X7whXyrIWR from "/opt/build/repo/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/opt/build/repo/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/opt/build/repo/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/opt/build/repo/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/opt/build/repo/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_client_5j40ijIZYu from "/opt/build/repo/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import scrollbars_client_Eg4DPJPcY6 from "/opt/build/repo/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import plugin_AUP22rrBAc from "/opt/build/repo/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import presets_1aypKNZ222 from "/opt/build/repo/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_kQtglGecod from "/opt/build/repo/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import forceDarkMode_XY13cXY4T3 from "/opt/build/repo/plugins/forceDarkMode.ts";
import errorHandler_x1Y4n5NP4w from "/opt/build/repo/plugins/errorHandler.ts";
import piniaPersist_UiJP2bzcQN from "/opt/build/repo/plugins/piniaPersist.ts";
import plugin_auto_pageviews_client_x3Y8XIaaPY from "/opt/build/repo/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
import plugin_client_QHzhVBB2Ll from "/opt/build/repo/.nuxt/nuxt-booster/plugin.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  plugin_client_5j40ijIZYu,
  scrollbars_client_Eg4DPJPcY6,
  plugin_AUP22rrBAc,
  presets_1aypKNZ222,
  variables_kQtglGecod,
  forceDarkMode_XY13cXY4T3,
  errorHandler_x1Y4n5NP4w,
  piniaPersist_UiJP2bzcQN,
  plugin_auto_pageviews_client_x3Y8XIaaPY,
  plugin_client_QHzhVBB2Ll
]