import { defineStore, createPinia, setActivePinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import type { Member } from "~/server/models/member.schema";

interface UserInfo extends Omit<Member, "_id"> {
  authenticated: boolean;
  id: string; // mapped from _id
}

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

export default { store: setActivePinia(pinia) };
export const useUserStore = defineStore("user", {
  state: () => ({
    userInfo: null as UserInfo | null,
    loginToken: null as string | null,
    stripeCustomerPortalUrl: null as string | null,
    isLoading: false,
  }),
  actions: {
    async login(token) {
      try {
        const response = await $fetch("/api/login", {
          method: "POST",
          body: { token },
        });
        if (response.success) {
          this.setUser(response.user);
          return true;
        }
      } catch (error) {
        console.error("Login error:", error);
      }
      return false;
    },
    setUser(userData: Partial<Member> | null) {
      console.log("Setting user data - Raw:", userData);
      if (!userData) {
        this.userInfo = null;
        return;
      }

      // Ensure we get an ID from either _id or id
      const id = userData._id?.toString() || userData.id || "";
      console.log("Extracted ID:", id);

      this.userInfo = {
        ...userData,
        authenticated: true,
        id, // Set the ID explicitly
        membership: {
          ...userData.membership,
          endDate: userData.membership?.endDate
            ? new Date(userData.membership.endDate)
            : null,
        },
      };

      console.log("Updated userInfo:", this.userInfo);
    },
    async fetchUserProfile() {
      try {
        this.isLoading = true;
        const response = await $fetch("/api/auth/me", {
          credentials: "include",
        });

        console.log("API Response:", response);

        if (response.authenticated) {
          // Ensure ID is present in the response
          const userData = {
            ...response,
            id: response._id?.toString() || response.id || "",
          };
          this.setUser(userData);

          if (!this.userInfo?.id) {
            console.error("ID missing after profile fetch");
          }
        } else {
          this.setUser(null);
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
        this.setUser(null);
      } finally {
        this.isLoading = false;
      }
    },
    updatePaymentInfo(paymentInfo) {
      if (this.userInfo && this.userInfo.membership) {
        this.userInfo.membership = {
          ...this.userInfo.membership,
          ...paymentInfo,
        };
      }
    },
    clearUser() {
      this.setUser(null);
      this.stripeCustomerPortalUrl = null;
    },
    async fetchStripeCustomerPortalUrl() {
      if (
        !this.userInfo?.id ||
        this.userInfo?.membership.paymentMethod !== "stripe"
      ) {
        console.log("User not eligible for Stripe customer portal");
        return;
      }
      try {
        const response = await $fetch("/api/stripe-create-portal", {
          method: "POST",
          body: { customerId: this.userInfo.membership.stripeCustomerId },
        });
        this.stripeCustomerPortalUrl = response.url;
        console.log(
          "Stripe customer portal URL set:",
          this.stripeCustomerPortalUrl
        );
      } catch (error) {
        console.error("Error fetching Stripe customer portal URL:", error);
      }
    },

    async logout() {
      try {
        await $fetch("/api/auth/logout", { method: "DELETE" });
        this.clearUser();
        return true;
      } catch (error) {
        console.error("Logout error:", error);
        return false;
      }
    },

    clearStripeCustomerPortalUrl() {
      this.stripeCustomerPortalUrl = null;
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.userInfo,
    fullName: (state) =>
      state.userInfo
        ? `${state.userInfo.firstName} ${state.userInfo.lastName}`
        : "",

    isActiveMember: (state) => state.userInfo?.membership?.status === "active",
    paymentMethod: (state) => state.userInfo?.membership?.paymentMethod,
    paymentType: (state) => state.userInfo?.membership?.paymentType,
    lastPaymentDate: (state) => state.userInfo?.membership?.lastPaymentDate,
    lastPaymentAmount: (state) => state.userInfo?.membership?.lastPaymentAmount,
    getUserId: (state) => state.userInfo?.id,
    memberId: (state) => state.userInfo?.id || "",
  },
  persist: {
    storage: persistedState.localStorage,
  },
});
